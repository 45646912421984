import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import style from './dashboard.module.scss';
import sponser from '../../../assets/images/dashboard/sponcer.png';
import TileItems from './TileItems';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

function Tile(props) {
    const { t } = useTranslation()

    return (
        <div className={style.dashboard_boxs_listing_sponcer_sec}>

            <Row className='fll_wdth_row_tab'>
                <Col md={7} className={style.mid_flll_wdth}>
                    {props.lodervalue &&   
                        <div className={style.boxes_lst_sec}>
                            {[1,2,3,4].map((items, index) => {
                            return (
                                <div className={style.list_boxes_main} >
                                <div className={`${style.dashboard_pannel_box} `}>

                                    <div className={style.listing_box_sec}>
                                        <div >
                                            <Skeleton circle={true} height={90} width={90} />
                                        </div>
                                        <div className={style.listing_box_head}>
                                            <Skeleton width={100} count={1} />
                                        </div>
                                        <div className={style.listing_box_cnout}><Skeleton width={50} count={1} /></div>
                                    </div>
                                </div>
                            </div>
                            ) 
                            })}
                        </div>}
                    {props.Tiles && props.Tiles.length > 0 &&
                        <div className={style.boxes_lst_sec}>
                            {props.Tiles.map((items, index) => {

                                return (
                                    <TileItems
                                        amount={items.amount}
                                        text={items.text}
                                        to={items.to}
                                        key={index}
                                        boxname={index}
                                        filter={items.filter}
                                        filterChange={props.filterChange}
                                        lodervalue={props.lodervalue}
                                    />

                                )


                            })}

                        </div>
                    } 
                </Col>
                <Col md={5} className={style.mid_flll_wdth}>

                    {props.lodervalue &&


                        <div className={style.sponcer_sec}>
                            <div className={style.dashboard_pannel_box}>
                                <div className={style.sponcer_top_name_sec}>
                                    <div className={style.sponcer_top_name_txt}>


                                        <Skeleton width={170} count={1} />
                                        <Skeleton width={110} count={1} />

                                    </div>

                                    <div className={style.sponcer_top_ico}> <Skeleton width={60} height={80} count={1} /></div>
                                    <div className={style.spouncer_cnt_row}>
                                        {[1, 2, 3, 4].map((data, index) => {
                                            return (
                                                <div className={style.spouncer_cnt_boxes} key={index}>
                                                    <Skeleton width={110} count={1} />
                                                    <Skeleton width={80} count={1} />
                                                </div>

                                            )
                                        })
                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    }




                    {props.SponserData &&

                        <div className={style.sponcer_sec}>
                            <div className={style.dashboard_pannel_box}>
                                <>
                                    {props.SponserData[0].text === 'sponsorName' &&
                                        <div className={style.sponcer_top_name_sec}>
                                            <div className={style.sponcer_top_name_txt}>

                                                <span>{t('Common.sponsor')}</span>
                                                {props.SponserData[0].head}

                                            </div>

                                            <div className={style.sponcer_top_ico}><img src={sponser} alt="" /></div>
                                        </div>
                                    }

                                    <div
                                        className={style.spouncer_cnt_row}>
                                        {props.pvData.map((data, index) => {
                                            return (
                                                <div className={style.spouncer_cnt_boxes} key={index}>
                                                    {t('Common.' + data.text)}
                                                    <strong>{data.head}</strong>
                                                </div>

                                            )
                                        })
                                        }


                                    </div>
                                </>


                            </div>

                        </div>
                    }

                </Col>
            </Row>

        </div>


    )


}

export default Tile;