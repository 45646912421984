//live

import companyName from '../store/mobxStore/companyName';
const COMPANY_NAME =companyName.companyName??'Company Name';
const BASE_URL = "https://citrifoliamlm.com/backoffice/api/";
const IMG_URL = "https://citrifoliamlm.com/uploads/images/";
const BASE = "https://citrifoliamlm.com/";
const API_KEY =localStorage.getItem('apiKey');
const DEFAULT_KEY = 'fa63b9f6-82a2-413a-ac38-d3aabd6e5792'

//test

// import companyName from '../store/mobxStore/companyName';
// const COMPANY_NAME =companyName.companyName??'Company Name';
// const BASE_URL = "https://citrifoliamlm.com/testapp/backoffice/api/";
// const IMG_URL = "https://citrifoliamlm.com/uploads/images/";
// const BASE = "https://citrifoliamlm.com/";
// const API_KEY =localStorage.getItem('apiKey');
// const DEFAULT_KEY = 'fa63b9f6-82a2-413a-ac38-d3aabd6e5792'

export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE, 
   DEFAULT_KEY

}